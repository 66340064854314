<template>
    <b-row>
        <b-col cols="12" md="8">
            <b-card title="Build Error Analysis Create" v-if="!isLoading">
                <validation-observer ref="appForm" tag="form"
                                     @submit.prevent="validationFormControl().then(formSubmitted)">
                    <b-row class="align-items-center">
                        <b-col md="12">
                            <b-form-group label="Name" label-for="name">
                                <validation-provider #default="{ errors }" name="name" maxlength="3"
                                                     rules="required">
                                    <b-form-input :state="errors.length > 0 ? false:null" placeholder="Build Error Name"
                                                  v-model="errorBuildData.name"/>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-form-group label="Regex" label-for="code">
                                <validation-provider #default="{ errors }" name="code" rules="required">
                                    <b-form-textarea :state="errors.length > 0 ? false:null" name="regex" placeholder="Regex" rows="3"  v-model="errorBuildData.regex" />
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col md="12" class="mt-1">
                            <validation-provider #default="{ errors }" name="Description">
                                <b-form-group label="Description" label-for="buildErrorDescription"
                                              :state="errors.length > 0 ? false:null">
                                    <quill-editor :options="editorOption" id="quilBrief"
                                                  v-model="errorBuildData.description"/>

                                    <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="12" class="mt-1">
                            <validation-provider #default="{ errors }" name="Description">
                                <b-form-group label="Solution" label-for="buildErrorSolution"
                                              :state="errors.length > 0 ? false:null">
                                    <quill-editor :options="editorOption" id="quilBrief"
                                                  v-model="errorBuildData.solution"/>

                                    <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" class="text-right">
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary">
                                Create
                            </b-button>
                        </b-col>
                    </b-row>
                </validation-observer>
            </b-card>
            <b-card v-if="isLoading" class="mt-2">
                <div class="text-center my-2">
                    <b-spinner class="d-block mx-auto mb-50"></b-spinner>
                    <strong>Content is being created, please wait...</strong>
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import vSelect from 'vue-select'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import AppCollapse from '@/components/AppCollapse.vue'
import AppCollapseItem from '@/components/AppCollapseItem.vue'
import router from '@/router'
import {required} from '@validations'

import 'quill/dist/quill.snow.css'
import {quillEditor} from 'vue-quill-editor'
import {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BSpinner,
    BFormTextarea
} from 'bootstrap-vue'

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        BRow,
        BCard,
        BCol,
        BFormGroup,
        BForm,
        BButton,
        BFormInput,
        vSelect,
        BFormInvalidFeedback,
        ToastificationContent,
        BFormCheckbox,
        AppCollapse,
        AppCollapseItem,
        BSpinner,
        quillEditor,
        BFormTextarea
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            errorBuildData: {},
            required,
            isLoading: false,
            editorOption: {
                theme: 'snow',
                modules: {
                    toolbar:  [
                        ['bold', 'italic', 'underline'],
                        ['blockquote'],
                        ['image', 'video'],
                        [{ 'header': 1 }, { 'header': 2 }],
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        [{ 'indent': '-1'}, { 'indent': '+1' }],
                        [{ 'color': [] }, { 'background': [] }],
                        [{ 'align': [] }],
                        ['clean']
                    ],
                    imageResize: {
                        displaySize: true
                    }
                },
            },
        }
    },
    methods: {
        formSubmitted() {
            this.isLoading = true
            this.$store.dispatch('buildErrorAnalysisStore/addBuildErrorAnalysis', this.errorBuildData)
                .then((response) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Successfully added!',
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    router.push({name: 'buildErrorAnalysis-list'})
                }).catch((errors) => {
                console.log(errors)
                this.isLoading = false
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Please check form',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: errors.response ? errors.response.data.message : ''
                    },
                })
            })
        },
        validationFormControl() {
            return new Promise((resolve, reject) => {
                this.$refs.appForm.validate().then(success => {
                    if (success) {
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            })
        },
    },
}
</script>
<style lang="scss">
.ql-editor{
    min-height:150px;
}
.ql-toolbar{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid #ebe9f1!important;
}
.ql-container{
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid #ebe9f1!important;
}
.is-invalid .quill-editor{
    border:1px solid red;
    border-radius: 5px;
}
.repeater-form {
    overflow: hidden;
    transition: .35s height;
}
</style>